<template>
    <div>
        <div class="row mt-4">
            <div class="col-12">
                <div class="card widget-inline">
                    <div class="card-body p-0">
                        <div class="row no-gutters">
                            <div class="col-sm-6 col-xl-6">
                                <div class="card shadow-none m-0">
                                    <div class="card-body text-center">
                                        <i class="dripicons-briefcase text-muted" style="font-size: 24px;"></i>
                                        <h3><span>{{consignment_count}}</span></h3>
                                        <p class="text-muted font-15 mb-0">Total Consignments</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-xl-6">
                                <div class="card shadow-none m-0 border-left">
                                    <div class="card-body text-center">
                                        <i class="dripicons-checklist text-muted" style="font-size: 24px;"></i>
                                        <h3><span>{{current_month_consignment_count}}</span></h3>
                                        <p class="text-muted font-15 mb-0">Total Consignments in this Month </p>
                                    </div>
                                </div>
                            </div>

                        </div> <!-- end row -->
                    </div>
                </div> <!-- end card-box-->
            </div> <!-- end col-->
        </div>
        <div class="row" v-if="consignment_count>0">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="dropdown float-right">
                            <a aria-expanded="false" class="dropdown-toggle arrow-none card-drop" data-toggle="dropdown"
                               href="#">
                                <i class="mdi mdi-dots-vertical"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                                <!-- item-->
                                <a @click="getConsignmentsInDuration('Last_7')" class="dropdown-item"
                                   href="javascript:void(0);">Last 7 Days</a>
                                <!-- item-->
                                <a @click="getConsignmentsInDuration('Last_30')" class="dropdown-item"
                                   href="javascript:void(0);">Last 30 Days</a>
                                <!-- item-->
                                <a @click="getConsignmentsInDuration()" class="dropdown-item"
                                   href="javascript:void(0);">All</a>
                            </div>
                        </div>
                        <h4 class="header-title mb-4">Consignments Overview</h4>

                        <div class="mt-3 chartjs-chart" style="height: 320px;" v-if="removeBarLoader">
                            <apexchart :options="options" :series="bar_series" height="320" type="bar"></apexchart>
                        </div>
                        <div class="d-flex justify-content-center mb-3" v-else>
                            <div class="spinner-border avatar-md text-primary" role="status"></div>
                        </div>

                    </div> <!-- end card body-->
                </div> <!-- end card -->
            </div><!-- end col-->
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import {mapGetters} from "vuex";

    export default {
        name: "AdminDashboard",
        computed: {
            ...mapGetters(["currentUser"])
        },
        data() {
            return {
                consignment_count: 0,
                current_month_consignment_count: 0,
                removeBarLoader: false,
                options: {
                    chart: {
                        id: 'vuechart-example',
                        type: 'bar',
                        stacked: true,
                    },
                    xaxis: {
                        categories: []
                    },
                    yaxis: {
                        title: {
                            text: 'Duration (Hours)'
                        },
                        labels: {
                            formatter: function (val) {
                                return Math.round(val) + "h"
                            }
                        }
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return "Duration " + val + " Hrs"
                            }
                        }
                    },
                    dataLabels: {
                        formatter: function (val) {
                            return val + " Hrs"
                        }
                    }
                },
                bar_series: [
                    {
                        name: 'In Progress',
                        data: []
                    },
                    {
                        name: 'Completed',
                        data: []
                    }
                ],
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                await Promise.all([
                    this.getConsignments(),
                    this.getConsignmentsCurrentMonth(),
                    this.getConsignmentsInDuration()
                ])
            },
            async getConsignments() {
                try {
                    let { data } = await this.$http.get(`mill/${this.currentUser.mill_id}/processes`, {
                        params: {
                            page: 0,
                            per_page: 1,
                            process_type: 'Consignment'
                        }
                    });
                    this.consignment_count = data.total;
                } catch (e) {
                    console.log(e)
                    this.$toastr.e('Failed to get processes', 'Failed');
                }
            },
            async getConsignmentsCurrentMonth() {
                try {
                    let { data } = await this.$http.get(`mill/${this.currentUser.mill_id}/processes`, {
                        params: {
                            page: 0,
                            per_page: 1,
                            process_type: 'Consignment',
                            from_date: moment().clone().startOf('month').format('YYYY-MM-DD'),
                            to_date: moment().clone().endOf('month').format('YYYY-MM-DD')
                        }
                    });
                    this.current_month_consignment_count = data.total;
                } catch (e) {
                    console.log(e)
                }
            },
            async getConsignmentsInDuration(duration) {
                this.removeBarLoader = false;
                this.options.xaxis.categories = [];
                this.bar_series[0].data = [];
                this.bar_series[1].data = [];
                let firstDay = null;
                switch (duration) {
                    case 'Last_7':
                        firstDay = moment().subtract(7, 'days').format('YYYY-MM-DD');
                        break;
                    case 'Last_30':
                        firstDay = moment().subtract(30, 'days').format('YYYY-MM-DD');
                }
                try {
                    let { data } = await this.$http.get(`mill/${this.currentUser.mill_id}/processes`, {
                        params: {
                            page: 0,
                            per_page: 100,
                            process_type: 'Consignment',
                            from_date: firstDay,
                            to_date: moment().format('YYYY-MM-DD')
                        }
                    });
                    data.processes.filter(s => s.status === 'InProgress')
                            .map(ms => {
                                this.options.xaxis.categories.push(ms.name)
                                this.bar_series[0].data.push(Math.round(moment.duration(moment().diff(ms.created)).asHours()));
                                this.bar_series[1].data.push(0);
                            });
                    data.processes.filter(s => s.status === 'Completed')
                            .map(ms => {
                                this.options.xaxis.categories.push(ms.name)
                                this.bar_series[0].data.push(0);
                                this.bar_series[1].data.push(Math.round(moment.duration(moment(ms.completed_at).diff(ms.created)).asHours()));
                            })
                } catch (e) {
                    console.log(e)
                } finally {
                    this.removeBarLoader = true;
                }
            },
        }
    }
</script>

<style scoped>

</style>