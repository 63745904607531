<template>
    <div>
        <div class="row mt-3">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">Consignments Overview</h4>
                </div>
                <div class="card widget-inline">
                    <div class="card-body  d-lg-none d-md-none"  v-if="removeLoader">
                        <div class="row text-center">
                            <div class="col-3">
                                <i class="dripicons-list text-muted"></i>
                                <p class="font-15 mb-0">{{total_tasks}}</p>
                                <h6 class="text-truncate d-block mb-0">Total</h6>
                            </div>
                            <div class="col-3">
                                <i class="dripicons-hourglass text-danger"></i>
                                <p class="font-15 mb-0">{{awaiting_tasks}}</p>
                                <h6 class="text-truncate d-block mb-0">Awaiting</h6>
                            </div>
                            <div class="col-3">
                                <i class="dripicons-checklist text-primary"></i>
                                <p class="font-15 mb-0">{{primary_tasks}}</p>
                                <h6 class="text-truncate d-block mb-0">Primary</h6>
                            </div>
                            <div class="col-3">
                                <i class="dripicons-thumbs-up text-success"></i>
                                <p class="font-15 mb-0">{{signed_tasks}}</p>
                                <h6 class="text-truncate d-block mb-0">Signed</h6>
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-0 d-none d-sm-block" v-if="removeLoader">
                        <div class="row no-gutters">
                            <div class="col-sm-6 col-xl-3">
                                <div class="card shadow-none m-0">
                                    <div class="card-body text-center">
                                        <i class="dripicons-list text-muted" style="font-size: 24px;"></i>
                                        <h3><span>{{total_tasks}}</span></h3>
                                        <p class="text-muted font-15 mb-0">Total Consignment Steps</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-xl-3">
                                <div class="card shadow-none m-0 border-left">
                                    <div class="card-body text-center">
                                        <i class="dripicons-hourglass text-danger" style="font-size: 24px;"></i>
                                        <h3><span>{{awaiting_tasks}}</span></h3>
                                        <p class="text-muted font-15 mb-0">Awaiting Consignment Steps</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-xl-3">
                                <div class="card shadow-none m-0 border-left">
                                    <div class="card-body text-center">
                                        <i class="dripicons-checklist text-primary" style="font-size: 24px;"></i>
                                        <h3><span>{{primary_tasks}}</span></h3>
                                        <p class="text-muted font-15 mb-0">Primary Consignment Steps</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-xl-3">
                                <div class="card shadow-none m-0 border-left">
                                    <div class="card-body text-center">
                                        <i class="dripicons-thumbs-up text-success" style="font-size: 24px;"></i>
                                        <h3><span>{{signed_tasks}}</span></h3>
                                        <p class="text-muted font-15 mb-0">Signed Consignment Steps</p>
                                    </div>
                                </div>
                            </div>
                        </div> <!-- end row -->
                    </div>
                    <div class="d-flex justify-content-center mt-3 mb-3" v-else>
                        <div class="spinner-border avatar-md text-primary" role="status"></div>
                    </div>
                </div> <!-- end card-box-->
            </div> <!-- end col-->
        </div>
        <div class="row" v-if="total_tasks>0">
            <div class="col-lg-8">
                <div class="card">
                    <div class="card-body">
                        <div class="dropdown float-right mx-sm-2">
                            <select class="custom-select custom-select-sm" id="status-select" v-model="filter_task_status">
                                <option value="Primary">Primary</option>
                                <option value="Awaiting">Awaiting</option>
                                <option value="Signed">Signed</option>
                            </select>
                        </div>
                        <h4 class="header-title mb-2">Consignment Steps</h4>
                        <p v-if="filter_task_status === 'All' && tasks.length>0"><b>{{total_tasks}}</b> Total tasks</p>
                        <p v-if="filter_task_status === 'Primary' && tasks.length>0"><b>{{primary_tasks}}</b>&nbsp;Primary tasks</p>
                        <p v-if="filter_task_status === 'Awaiting' && tasks.length>0"><b>{{awaiting_tasks}}</b> Awaiting tasks</p>
                        <p v-if="filter_task_status === 'Signed' && tasks.length>0"><b>{{signed_tasks}}</b> Completed tasks</p>
                        <div class="table-responsive" v-if="tasks.length>0">
                            <table class="table table-centered table-nowrap table-hover mb-0">
                                <tbody>
                                <tr @click="goToSteps(task.name)" style="cursor: pointer" v-bind:key="index"
                                    v-for="(task, index) in tasks">
                                    <td>
                                        <h5 class="font-14 my-1"><a class="text-body" href="javascript:void(0);">{{task.name}}</a>
                                        </h5>
<!--                                        <span class="text-muted font-13"-->
<!--                                              v-if="task.step.status === 'Attested' && task.due > 0">Signed {{task.due}} days ago</span>-->
<!--                                        <span class="text-muted font-13"-->
<!--                                              v-else-if="task.step.status === 'Attested' && task.due === 0">Signed today</span>-->
                                        <span class="text-muted font-13"
                                              v-if="task.step && task.step.order >= task.current_step && task.due === 0">Arrived today</span>
                                        <span class="text-muted font-13"
                                              v-else-if="task.step && task.step.order >= task.current_step && task.due > 0">Due in {{task.due}} days</span>
                                    </td>
                                    <td>
                                        <span class="text-muted font-13">Truck No.</span>
                                        <h5 v-if="task.attributes.length>0" class="font-14 mt-1 font-weight-normal">{{task.attributes[0][2].fact.value}}</h5>
                                        <h5 v-else class="font-14 mt-1 font-weight-normal">NA</h5>
                                    </td>
                                    <td>
                                        <span class="text-muted font-13">Assigned to</span>
                                        <h5 v-if="task.step" class="font-14 mt-1 font-weight-normal">{{task.step.name}}</h5>
                                    </td>
                                    <td>
                                        <span class="text-muted font-13">Signing Status</span> <br>
                                        <span class="badge badge-primary-lighten"
                                              v-if="task.step && task.step.order === task.current_step">Primary</span>
                                        <span class="badge badge-success-lighten"
                                              v-else-if="task.step && task.step.order < task.current_step">Signed</span>
                                        <span class="badge badge-danger-lighten"
                                              v-else>Awaiting</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div> <!-- end table-responsive-->

                        <div class="d-flex justify-content-center mb-3" v-else-if="!removeTaskLoader">
                            <div class="spinner-border avatar-md text-primary" role="status"></div>
                        </div>
                        <template v-else>
                            <h4 class="card-title text-muted text-center mt-lg-4">No records found.</h4>
                            <h5 class="card-title text-muted text-center mb-lg-4">Change search criteria and try
                                again</h5>
                        </template>
                    </div> <!-- end card body-->
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-auto p-t-5">
                                        <select @change="per_pageRecordChange()" aria-controls="lang_opt"
                                                class="form-control custom-select" name="lang_opt_length"
                                                v-model="per_page">
                                            <option value="5">5</option>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select></div>
                                    <label class="d-none d-sm-block col-form-label">Records per page</label></div>
                            </div>
                            <div class="col-6">
                                <paginate :click-handler="pageHandler"
                                          :container-class="'pagination float-right'"
                                          :margin-pages="2"
                                          :next-class="'page-link'"
                                          :next-text="'Next'"
                                          :page-class="'page-link'"
                                          :page-count=pageCount
                                          :page-range="1"
                                          :prev-class="'page-link'"
                                          :prev-text="'Prev'">
                                </paginate>
                            </div>
                        </div>
                    </div>
                </div> <!-- end card -->
            </div>
            <div class="col-lg-4">
                <div class="card">
                    <div class="card-body">
                        <h4 class="header-title mb-4">Signing Status</h4>
                        <template v-if="removeLoader">
                            <apexchart :options="chartOptions" :series="series" type="donut"></apexchart>
                            <div class="row text-center mt-2 py-2">
                                <div class="col-4">
                                    <i class="mdi mdi-trending-down text-primary mt-3 h3"></i>
                                    <h3 class="font-weight-normal">
                                        <span>{{(Number(primary_tasks/total_tasks)*100).toFixed(2)}}%</span>
                                    </h3>
                                    <p class="text-muted mb-0"> Primary</p>
                                </div>
                                <div class="col-4">
                                    <i class="mdi mdi-trending-up text-success mt-3 h3"></i>
                                    <h3 class="font-weight-normal">
                                        <span>{{(Number(signed_tasks/total_tasks)*100).toFixed(2)}}%</span>
                                    </h3>
                                    <p class="text-muted mb-0"> Signed</p>
                                </div>
                                <div class="col-4">
                                    <i class="mdi mdi-trending-down text-danger mt-3 h3"></i>
                                    <h3 class="font-weight-normal">
                                        <span>{{(Number(awaiting_tasks/total_tasks)*100).toFixed(2)}}%</span>
                                    </h3>
                                    <p class="text-muted mb-0"> Awaiting</p>
                                </div>
                            </div>
                        </template>

                        <div class="d-flex justify-content-center mb-3" v-else>
                            <div class="spinner-border avatar-md text-primary" role="status"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import {mapGetters} from "vuex";
    import Paginate from 'vuejs-paginate';

    export default {
        name: "ConsignmentAttesterDashboard",
        computed: {
            ...mapGetters(["currentUser"])
        },
        components: {Paginate},
        watch: {
            "filter_task_status": function (nv, ov) {
                if (nv) {
                    if (nv === 'Primary') {
                        this.getPrimaryTasks(1);
                    } else if (nv === 'Awaiting') {
                        this.getAwaitingTasks(1);
                    } else if (nv === 'Signed') {
                        this.getSignedTasks(1);
                    }
                }
            }
        },
        data() {
            return {
                tasks: [],
                total_tasks: 0,
                primary_tasks: 0,
                signed_tasks: 0,
                awaiting_tasks: 0,
                series: [],
                chartOptions: {
                    labels: ['Primary', 'Signed', 'Awaiting'],
                    dataLabels: {
                        formatter: function (val) {
                            return ''
                        },
                    }
                },
                removeLoader: false,
                removeTaskLoader: false,
                pageCount: 1,
                per_page: 5,
                total: 0,
                filter_task_status: 'Primary',
                attester_steps: []
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                await this.getProcessDefinitionSteps();
                await Promise.all([
                        this.getPrimaryTasks(1),
                        this.getAwaitingTasks(1),
                        this.getSignedTasks(1)
                ]).then(res => {
                    this.total_tasks = Number(this.primary_tasks)+ Number(this.awaiting_tasks)+ Number(this.signed_tasks);
                    this.series[0] = this.primary_tasks;
                    this.series[1] = this.signed_tasks;
                    this.series[2] = this.awaiting_tasks;
                    this.removeLoader = true;
                }).catch(e => {
                    this.removeLoader = true;
                })
            },
            async getProcessDefinitionSteps() {
                try {
                    let { data } = await this.$http.get(`mill/${this.currentUser.mill_id}/process_definition_steps`, {
                        params: {
                            page: 0,
                            per_page: 100,
                            process_type: 'Consignment'
                        }
                    });
                    let that = this;
                    this.attester_steps = data.process_definition_steps.filter(s=> s.attestors.some(function (sa) {
                        return sa.did === that.currentUser.did
                    }));
                } catch (e) {
                    console.error(e);
                }
            },
            async getPrimaryTasks(page){
                if(this.attester_steps.length>0) {
                    try {
                        this.tasks = [];
                        this.removeTaskLoader = false;
                        let {data} = await this.$http.get(`mill/${this.currentUser.mill_id}/processes`, {
                            params: {
                                process_type: 'Consignment',
                                page: page - 1,
                                per_page: this.per_page,
                                from_step: this.attester_steps[0].order,
                                to_step: this.attester_steps[this.attester_steps.length - 1].order,
                                fetch: {
                                    attributes: [{
                                        attribute_name: "Truck No.",
                                        attribute_step: 0
                                    }]
                                }
                            }
                        });
                        this.primary_tasks = data.total;
                        if(this.filter_task_status === 'Primary'){
                            let tasks = data.processes;
                            let datas = await Promise.all(
                                    tasks.map(task => {
                                        let attester_step = this.attester_steps.find(as => as.order === task.current_step);
                                        return this.$http.get(`mill/${this.currentUser.mill_id}/processes/${task.process}/process_steps_by_process_definition_step`, {
                                            params: {
                                                process_type: 'Consignment',
                                                process_definition_step: attester_step.process_definition_step
                                            }
                                        });

                                    })
                            );
                            this.tasks = tasks.map((t, index)=> {
                                let {data} = datas[index];
                                let step = null;
                                if(data){
                                    data.name = data.process_definition_step_name;
                                    step = data;
                                }
                                return {
                                    ...t,
                                    step: step,
                                    due: data? moment(new Date().toISOString().substring(0, 10)).diff(moment(data.created.substring(0, 10)), "days"): null
                                }
                            });
                            this.total = data.total;
                        }
                        this.setPageCount();
                    } catch (e) {
                        console.error(e)
                    }finally {
                        this.removeTaskLoader = true;
                    }
                }
            },
            async getAwaitingTasks(page){
                if(this.attester_steps.length>0) {
                    try {
                        this.tasks = [];
                        this.removeTaskLoader = false;
                        let {data} = await this.$http.get(`mill/${this.currentUser.mill_id}/processes`, {
                            params: {
                                process_type: 'Consignment',
                                page: page - 1,
                                per_page: this.per_page,
                                to_step: this.attester_steps[0].order,
                                fetch: {
                                    attributes: [{
                                        attribute_name: "Truck No.",
                                        attribute_step: 0
                                    }]
                                }
                            }
                        });
                        this.awaiting_tasks = data.total;
                        if(this.filter_task_status === 'Awaiting'){
                            let tasks = data.processes;
                            this.tasks = tasks.map(t=> ({
                                ...t,
                                step: this.attester_steps.find(as => as.order > t.current_step),
                                due: moment(new Date().toISOString().substring(0, 10)).diff(moment(t.created.substring(0, 10)), "days")

                            }));
                            this.total = data.total;
                        }
                        this.setPageCount();
                    } catch (e) {

                    }finally {
                        this.removeTaskLoader = true;
                    }
                }
            },
            async getSignedTasks(page){
                if(this.attester_steps.length>0) {
                    try {
                        this.tasks = [];
                        this.removeTaskLoader = false;
                        let datas = await Promise.all(
                                this.attester_steps.map((task, index) => {
                                    return this.$http.get(`mill/${this.currentUser.mill_id}/processes`, {
                                        params: {
                                            process_type: 'Consignment',
                                            page: 0,
                                            per_page: 100,
                                            from_step: this.attester_steps[index].order+1,
                                            fetch: {
                                                attributes: [{
                                                    attribute_name: "Truck No.",
                                                    attribute_step: 0
                                                }]
                                            }
                                        }
                                    });

                                })
                        );
                        let tasks = [];
                        this.attester_steps.forEach((as, index)=> {
                            let {data} = datas[index];
                            let task = data.processes.map(p=> {
                                return {
                                    ...p,
                                    step: as
                                }
                            });
                            tasks = tasks.concat(task);
                        });
                        this.signed_tasks = tasks.length;
                        if(this.filter_task_status === 'Signed') {
                            this.tasks = tasks;
                            this.total = tasks.length;
                            let start = (page - 1) * this.per_page;
                            let end = Number(start) + Number(this.per_page);
                            this.tasks = tasks.slice(start, end);
                            this.setPageCount()
                        }
                        this.setPageCount();
                    } catch (e) {

                    }finally {
                        this.removeTaskLoader = true;
                    }
                }
            },
            goToSteps(processid) {
                this.$router.push({name: 'steps', params: {consignmentid: processid}})
            },
            per_pageRecordChange() {
                this.tasks = [];
                this.removeTaskLoader = false;
                if (this.filter_task_status === 'Primary') {
                    this.getPrimaryTasks(1);
                } else if (this.filter_task_status === 'Awaiting') {
                    this.getAwaitingTasks(1);
                } else if (this.filter_task_status === 'Signed') {
                    this.getSignedTasks(1);
                }
            },
            pageHandler(pageNum) {
                this.tasks = [];
                this.removeTaskLoader = false;
                if (this.filter_task_status === 'Primary') {
                    this.getPrimaryTasks(pageNum);
                } else if (this.filter_task_status === 'Awaiting') {
                    this.getAwaitingTasks(pageNum);
                } else if (this.filter_task_status === 'Signed') {
                    this.getSignedTasks(pageNum);
                }
            },
            setPageCount() {
                this.pageCount = this.total <= this.per_page ? 1 : Math.ceil(this.total / this.per_page);
            }
        }
    }
</script>

<style scoped>
    .page-link.active {
        z-index: 2;
        background-color: #3788fc;
        border-color: #3788fc;
    }

    .page-link.active a {
        color: #fff;
    }

    .mdrp__activator .activator-wrapper .text-field {
        padding: 0.45rem 0.9rem !important;;
        font-size: 0.875rem !important;;
        color: #4F5467 !important;;
        border: 1px solid #e9ecef !important;;
        border-radius: 2px !important;
        width: 220px !important;;
    }
</style>