<template>
    <!-- Start Content-->
    <div class="container-fluid">
        <admin-dashboard v-if="currentUser && currentUser.roles.includes('Admin')"></admin-dashboard>
        <consignment-attester-dashboard v-if="currentUser && currentUser.roles.includes('ConsignmentAttester')"></consignment-attester-dashboard>
        <batch-attester-dashboard v-if="currentUser && currentUser.roles.includes('BatchAttester')"></batch-attester-dashboard>
    </div>
    <!-- end row-->
    <!-- container -->
</template>

<script>
    import {mapGetters} from "vuex";
    import AdminDashboard from "./AdminDashboard";
    import ConsignmentAttesterDashboard from "./ConsignmentAttesterDashboard";
    import BatchAttesterDashboard from "./BatchAttesterDashboard";

    export default {
        name: "Dashboard",
        components: {BatchAttesterDashboard, ConsignmentAttesterDashboard, AdminDashboard},
        computed: {
            ...mapGetters(["currentUser"])
        }
    }
</script>

<style scoped>

</style>